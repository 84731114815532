body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// TO GO IN MASTER SCSS FILE ------------------------ //

$screen-sm-min: 576px;  // Small tablets and large smartphones (landscape view)
$screen-md-min: 768px;  // Small tablets (portrait view)
$screen-lg-min: 992px;  // Tablets and small desktops
$screen-xl-min: 1200px; // Large tablets and desktops

@mixin sm { @media (min-width: #{$screen-sm-min}) { @content; } }
@mixin md { @media (min-width: #{$screen-md-min}) { @content; } }
@mixin lg { @media (min-width: #{$screen-lg-min}) { @content; } }
@mixin xl { @media (min-width: #{$screen-xl-min}) { @content; } }

// -------------------------------------------------- //

@import './Visual/Visual';