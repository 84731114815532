.visual {
	text-align: center;

	.phoneContainer {
    display: inline-block;
		z-index: 2;
		padding: 28px 10px 10px 10px;
		border: 2px solid #ccc;
		background-color: #efefef;
		border-radius: 35px;
}
 
	.antenna {
		width: 52px;
		height: 6px;
		border-radius: 3px;
		background-color: #999;
		margin: 0 auto 14px;
	}
	
	.homeButton {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		background-color: #f6f6f6;
		margin: 5px auto 5px;
		border: 2px solid #ccc;
	}
	
	.visual-container-outer {
    width: 320px;
    height: 580px;
    max-width: 100%;
    max-height: 100%;
}

	.visual-container-inner,
	.visual-container-inner iframe {
			width: 100%;
			height: 100%;
			
			margin: 0 !important;
			padding: 0 !important;
			box-sizing: border-box;
	}

	.scroll {
			overflow: scroll !important;
			-webkit-overflow-scrolling: touch !important;
	}

	.no-scrollbar {
    position: relative;
	}

	.no-scrollbar iframe {
			position: absolute;
			top: 0;
			left: 0;
	}
}